import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import firebase from 'firebase/app'

import { auth } from 'lib/firebase'

export interface Profile {
  userId: string
  email?: string
  telegram_id?: string
  twitter_id?: string
  retweet_url?: string
  nickname?: string
  ticket_counter?: number
}
export interface UserContextProps {
  user: firebase.User | null
  profile: Profile | null
  authChecked: boolean
  setProfile: Dispatch<SetStateAction<Profile | null>>
  referral: string
  setReferral: Dispatch<SetStateAction<string>>
}

const UserContext = React.createContext<UserContextProps>({
  user: null,
  profile: null,
  authChecked: false,
  setProfile: () => {},
  referral: '',
  setReferral: () => {}
})

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [profile, setProfile] = useState<Profile | null>(null)
  const [authChecked, setAuthChecked] = useState<boolean>(false)
  const [referral, setReferral] = useState<string>('')

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(firebaseUser => {
      setUser(firebaseUser)
      setAuthChecked(true)
    })

    return unsubscribe
  }, [])

  return (
    <UserContext.Provider value={{ user, authChecked, profile, setProfile, referral, setReferral }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
