import React, { Suspense, useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import UserContext from 'contexts/UserContext'

const Login = React.lazy(() => import(/* webpackChunkName: "login-chunk" */ './containers/Login'))
const Profile = React.lazy(() => import(/* webpackChunkName: "profile-chunk" */ './containers/Profile'))

const Routes = () => {
  const { user, authChecked } = useContext(UserContext)
  if (!authChecked) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path='/'>
            {user ? <Redirect to='/me' /> : <Redirect to='/login' />}
          </Route>
          <Route path='/login'>{user ? <Redirect to='/me' /> : <Login />}</Route>
          <Route path='/me'>{user ? <Profile /> : <Redirect to='/login' />}</Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
