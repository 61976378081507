import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCzOWQPlTU4FHO5gjA3jm91NCekuEITPBU',
  authDomain: 'nft-lottery-campaign.firebaseapp.com',
  projectId: 'nft-lottery-campaign',
  storageBucket: 'nft-lottery-campaign.appspot.com',
  messagingSenderId: '1047293505280',
  appId: '1:1047293505280:web:a829ce00133a4a6f58cde5',
  measurementId: 'G-6RVWDT63C6'
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export default firebase
